import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'tab',
    'button',
  ];
  static values = {
    currentTabIndex: Number,
  }

  connect() {
    this.buttonTargets.forEach((buttonTarget, index) => {
      buttonTarget.addEventListener('click', () => {
        this.changeTab(index);
      });
    });
    this.displayTab();
  }

  changeTab(index) {
    this.currentTabIndexValue = index;
    this.displayTab();
  }

  displayTab() {
    this.tabTargets.forEach((tab, index) => {
      if (index == this.currentTabIndexValue) {
        tab.classList.remove('is-hidden-touch');
      } else {
        tab.classList.add('is-hidden-touch');
      }
    });
    this.buttonTargets.forEach((button, index) => {
      if (index == this.currentTabIndexValue) {
        button.classList.add('is-white');
        button.classList.remove('is-ghost');
      } else {
        button.classList.remove('is-white');
        button.classList.add('is-ghost');
      }
    });
  }
}
